import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyCpGor024AjbUDaTQ4ph6YUXwaDqmAU7dc",
    authDomain: "hann-writing.firebaseapp.com",
    databaseURL: "https://hann-writing.firebaseio.com",
    projectId: "hann-writing",
    storageBucket: "hann-writing.appspot.com",
    messagingSenderId: "481406961321",
    appId: "1:481406961321:web:8323e2df858cd861ec0bb8",
    measurementId: "G-W4GS2K81Z3"
};

if (!firebase.apps.length) { firebase.initializeApp(firebaseConfig) }
firebase.analytics()

export let database = firebase.firestore()
export let auth = firebase.auth()
