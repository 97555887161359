import React from 'react'

//styles

import "./notloggedin.scss"


function NotLoggedIn() {
    return (
        <div className="not-logged-in-background d-flex align-items-center">
            <div className="not-logged-in-background-text text-monospace text-primary text-center py-4">
                <h6>Sign Up / log in to see your writing history</h6>
                <h6>or</h6>
                <h6>use the search bar above to find your writing.</h6>
            </div>
        </div>
    )
}

export default NotLoggedIn
