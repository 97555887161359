import React, { useState } from 'react'
import { Modal, Button, Form, FormLabel, FormControl } from 'react-bootstrap'

import "./ModalTaskSelector.css"

function ModalTaskSelector({ taskThemes, taskTypes, show, close, submit, taskTime, taskName }) {

    const topic = "NA"
    const questionType = "NA"
    const [questionText, setQuestionText] = useState('')
    const [essayText, setEssayText] = useState('')

    const captureQuestionText = (event) => {
        setQuestionText(event.target.value);
    }
    const captureEssayText = (event) => {
        setEssayText(event.target.value);
    }

    const handleSubmit = () => {
        if (taskName === "Full Training") {
            if (questionText === "" || essayText === "") {
                alert("Both Task 1 & Task 2 questions are required")
                return
            }
            submit("/write_full", topic, questionText, essayText, questionType, taskTime);
        }
        else if (questionText !== "") {
            submit("/write_singletask", topic, questionText, essayText, questionType, taskTime);
            close();
        } else { alert("A question text is required") }

    }

    return (
        <Modal centered size={taskName === "Full Training" ? "xl" : ""} show={show} onHide={close} className="modal-border">
            <div className={taskName === "Full Training" ? "modal-grid-single-task" : "modal-grid-full-test"}>
                <div>
                    <Modal.Header className="bg-secondary text-monospace text-center justify-content-center">
                        <Modal.Title>
                            <FormLabel className=""> {taskName === "Full Training" ? "Task 1" : taskName}
                                <br />Paste your question</FormLabel>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="bg-secondary">
                        <Form>
                            <FormControl as="textarea" rows={10} onChange={captureQuestionText} />
                        </Form>
                    </Modal.Body>
                </div>
                {taskName !== "Full Training" ? <div></div> :
                    <div>
                        <Modal.Header className="bg-secondary text-monospace text-center justify-content-center">
                            <Modal.Title>
                                <FormLabel className=""> {taskName === "Full Training" ? "Task 2" : taskName}
                                    <br />Paste your question</FormLabel>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="bg-secondary">
                            <Form>
                                <FormControl as="textarea" rows={10} onChange={captureEssayText} />
                            </Form>
                        </Modal.Body>
                    </div>
                }
            </div>
            <Modal.Footer className="bg-secondary">
                <Button variant="danger" onClick={close}>
                    Close
                </Button>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Start
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalTaskSelector
