import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

//components
import NavigationBar from './components/layout/NavigationBar'
import Dashboard from './components/pages/dashboard/Dashboard';
import FullTrainingWriting from './components/pages/fullTrainingWriting/FullTrainingWriting';
import LandingPage from './components/pages/landingPage/LandingPage';
import QuestionPage from './components/pages/QuestionPage';
import ViewPrevWriting from './components/pages/viewPrevWriting/ViewPrevWriting';
import WritingPage from './components/pages/WritingPage';
import ResourcesPage from './components/pages/resourcesPage/ResourcesPage'
import './firebase/config'
import Footer from './components/layout/Footer';

function App() {
  return (
    <Router>
      <NavigationBar />
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/write_singletask" component={WritingPage} />
        <Route path="/write_full" component={FullTrainingWriting} />
        <Route path="/question" component={QuestionPage} />
        <Route path="/view_test/:id" component={ViewPrevWriting} />
        <Route path="/resources" component={ResourcesPage} />
        <Route path="/" component={LandingPage} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
