import React, { Fragment, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';

//components
import LogIn from '../auxComponents/signin/ModalLogin'
import SignUp from '../auxComponents/signup/ModalSignUp'

//firebase
import { auth } from "../../firebase/config";
import { database } from "../../firebase/config";

function NavigationBar() {

    const [showModalLogIn, setShowModalLogIn] = useState(false)
    const [showModalSignUp, setShowModalSignUp] = useState(false)
    const [user, setUser] = useState(auth.currentUser)

    const handleClose = () => {
        setShowModalLogIn(!showModalLogIn)
        if (!showModalLogIn && showModalSignUp) {
            setShowModalLogIn(false)
            setShowModalSignUp(!showModalSignUp)
        }

    }
    const signUp = () => {
        setShowModalLogIn(!showModalLogIn)
        setShowModalSignUp(!showModalSignUp)
    }

    const signIn = () => {
        setShowModalLogIn(!showModalLogIn)
        setShowModalSignUp(!showModalSignUp)
    }

    const userHandle = () => {
        handleClose()
    }

    const logoutHandle = () => {
        auth.signOut()
        setUser(null)
    }

    var loginButton = !user ?
        <Nav>
            <Button onClick={userHandle}>
                Log In / Register
            </Button>
        </Nav> :
        <Nav>
            <Link className="btn btn-primary" onClick={logoutHandle} to="/">Logout</Link>
        </Nav>

    var userInfo = !user ? <div></div> :
        <div className="bg-dark">
            <Container className="d-flex justify-content-end text-white">
                <h6 className="p-1 m-2">
                    Welcome, {user.user_firstName} {user.user_lastName}
                </h6>
                <div className="p-1 m-2 ml-2 text-primary bg-light rounded-circle">
                    {user.user_initials}
                </div>
            </Container>
        </div>

    return (
        <Fragment>
            <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" className="text-monospace " >
                <Container className="justify-content-bewteen">
                    <div>
                        <Link to="/" style={{ textDecoration: 'none' }} >
                            <Navbar.Brand>
                                Simulation IELTS
                            </Navbar.Brand>
                        </Link>
                    </div>
                    <div>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Link className="btn btn-primary" to="/dashboard">Dashboard</Link>
                            <Link className="btn btn-primary" to="resources">Resources</Link>
                            {loginButton}
                        </Navbar.Collapse>
                    </div>
                </Container>
            </Navbar>
            {userInfo}
            <LogIn
                show={showModalLogIn}
                close={handleClose}
                signUp={signUp}
                auth={auth}
                setUser={setUser}
                database={database}
            />
            <SignUp
                show={showModalSignUp}
                close={handleClose}
                signIn={signIn}
                auth={auth}
                setUser={setUser}
                database={database}
            />
        </Fragment>
    );
}

export default NavigationBar;
