import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import './questionPage.css'


export default function QuestionPage(props) {

    const initialState = ""
    const [question, setquestion] = useState(initialState)
    const [questionType, setQuestionType] = useState(1200)

    const captureQuestion = event => {
        setquestion(event.target.value);
    }
    const captureQuestionType = (event) => {
        setQuestionType(event.target.value)
    }


    const saveQuestion = () => {
        if (question !== "") { props.history.push("/write", { question, questionType }) } else { alert("question is required") }
    }
    return (
        <main>
            <Container>
                <h3 className="mt-4">What would you like to practice?</h3>
                <Row>
                    <Col>
                        <h4>Task 1</h4>
                        <Button variant="dark" className="mt-4 mb-2 py-3" block onClick={saveQuestion}>GT Apology Letter</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>GT Amendment Letter</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>GT Complaint Letter</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>GT Letter to a friend</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>GT Invitation Letter</Button>
                        <Button variant="dark" className="mt-4 mb-2 py-3" block onClick={saveQuestion}>A Charts</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>A Diagrams</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>A Flows</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>A Maps</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>A Combined</Button>
                    </Col>
                    <Col>
                        <h4>Task 2</h4>
                        <Button variant="dark" className="mt-4 mb-2 py-3" block onClick={saveQuestion}>Opinion</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>Advatages & Disadvantages</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>Problem Solution</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>Discussion</Button>
                        <Button variant="dark" className="my-2 py-3" block onClick={saveQuestion}>Two Part Question</Button>
                    </Col>
                    <Col>
                        <h4>Full Test</h4>
                        <Button variant="dark" className="my-4 py-4" block onClick={saveQuestion}>
                            <h5>Task 1 General Training</h5>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id, hic!</p>
                        </Button>
                        <Button variant="dark" className="my-4 py-4" block onClick={saveQuestion}>
                            <p>Task 1 Academic</p>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id, hic!</p>
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container>
                <h3 className="mt-4">Please paste your question in the below textbox</h3>
                <Form.Label className="pr-4" >What task would you like to practice?</Form.Label>
                <Form.Check inline label="Task 1" value={1200} id="task1" type="radio" checked={questionType === 1200} onChange={captureQuestionType} />
                <Form.Check inline label="Task 2" value={2400} id="task2" type="radio" checked={questionType === 2400} onChange={captureQuestionType} />
                <Form.Control as="textarea" rows={10} onChange={captureQuestion} spellCheck={false} />
                <Button variant="dark" className="my-4 py-4" block onClick={saveQuestion}>START TEST</Button>
            </Container>
        </main>
    )
}
