import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap'
import "./fullTrainingWriting.css"
//backend
import { database } from '../../../firebase/config'
import { auth } from '../../../firebase/config'


function FullTrainingWriting(props) {

    const [showAlert, setShowALert] = useState(false)
    const [task1, setTask1] = useState(true)
    const task1Question = props.history.location.state !== undefined ? props.history.location.state.questionText : props.history.push("/");
    const task2Question = props.history.location.state !== undefined ? props.history.location.state.essayText : props.history.push("/")
    const [task1Answer, setTask1Answer] = useState("")
    const [task2Answer, setTask2Answer] = useState("")
    const [wordCounter, setWordCounter] = useState(0)
    const [timer, setTimer] = useState(60)
    const [writingId, setWritingId] = useState('')

    useEffect(() => {
        var date = new Date()
        async function saveTempData() {
            const res = await database.collection('answers').add({
                temporarySaveFT: {
                    "ans_gradedBy": "teacher",
                    "ans_gradedComments": "Teacher comments here",
                    "ans_question_task1_text": task1Question,
                    "ans_question_task2_text": task2Question,
                    "ans_response_task1": "",
                    "ans_response_task2": "",
                    "ans_score": "7",
                    "ans_timeTook": "",
                    "ans_user": auth.currentUser ? auth.currentUser.email : "anonymous",
                    "ans_wordCount": "",
                    "date_time": date
                }
            })
            setWritingId(res.id)
        }
        saveTempData()
    }, [task1Question, task2Question])

    const timerDecreaser = () => {
        setTimeout(() => { setTimer(timer - 1) }, 60000)
        return timer
    }

    const countWords = (textToCount) => {
        let text = []
        text = textToCount.match(/\w+/g) ? textToCount.match(/\w+/g).push() : 0
        setWordCounter(text)
    }

    const handleButton1 = () => {
        setTask1(true)
        countWords(task1Answer)
    }

    const handleButton2 = () => {
        setTask1(false)
        countWords(task2Answer)
    }

    const onChangeAnswer1 = (event) => {
        setTask1Answer(event.target.value)
        localStorage.setItem('task1', task1Answer)
        countWords(task1Answer)
    }

    const onChangeAnswer2 = (event) => {
        setTask2Answer(event.target.value)
        localStorage.setItem('task2', task2Answer)
        countWords(task2Answer)
    }

    const askConfirmation = () => {
        setShowALert(true)
    }

    const dismissConfirmation = () => {
        setShowALert(false)
    }

    const saveAnswer = async () => {
        const countFinalWords = (textToCount) => {
            let text = []
            text = textToCount.match(/\w+/g) ? textToCount.match(/\w+/g).push() : 0
            return text
        }
        var date = new Date()
        const task1 = {
            "ans_gradedBy": "teacher",
            "ans_gradedComments": "Teacher comments here",
            "ans_question_text": task1Question,
            "ans_response": task1Answer,
            "ans_score": "7",
            "ans_timeTook": timer - 20,
            "ans_wordCount": countFinalWords(task1Answer),
            "date_time": date
        }
        const task2 = {
            "ans_gradedBy": "teacher",
            "ans_gradedComments": "Teacher comments here",
            "ans_question_text": task2Question,
            "ans_response": task2Answer,
            "ans_score": "7",
            "ans_timeTook": timer - 40,
            "ans_wordCount": countFinalWords(task2Answer),
            "date_time": date
        }
        await database.collection('answers').doc(writingId).set({ "ans_user": auth.currentUser ? auth.currentUser.email : "anonymous", task1, task2 })
        setWordCounter(0)
        setTask1Answer('')
        setTask2Answer('')
        localStorage.clear("task1")
        localStorage.clear("task2")
        props.history.push("/")
    }

    return (
        <main>
            <Modal
                show={showAlert}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Container className="p-4 d-flex flex-column align-items-center text-center">
                    <div className="m-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="orange" className="bi bi-exclamation-diamond" viewBox="0 0 16 16">
                            <path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                        </svg>
                    </div>
                    <p className="text-center">Are you sure you want to save your test? be aware of:</p>
                    <ol className="text-left">
                        <li>Your work is saved locally temporarly</li>
                        <li>You can Retrieve your writing with the code {writingId}</li>
                        <li>You will be redirected to homepage</li>
                    </ol>
                    <div className='text-center'>
                        <Button className="m-4" variant="success" onClick={saveAnswer}>yes, I'm sure</Button>
                        <Button className="m-4" variant="danger" onClick={dismissConfirmation}>No, Cancel</Button>
                    </div>
                </Container>
            </Modal>
            <div className="bg-dark">
                <Container fluid className="d-flex justify-content-between text-white">
                    <span >Writing ID {writingId}</span>
                    <span>{timerDecreaser()} min left</span>
                    <span> </span>
                </Container>
            </div>
            <Container fluid >
                <div className="border mx-1 my-2 pt-3 pl-3">
                    <h4 className="mb-3">Part {task1 ? "1" : "2"}</h4>
                    <p>You should spend about {task1 ? "20" : "40"} minutes on this part. Write at least {task1 ? "150" : "250"} words</p>
                </div>
                <Row className="mx-1 shadow mid-height">
                    <Col>
                        <Form.Control as="textarea"
                            placeholder={task1 ? task1Question : task2Question}
                            className="mt-4 border-0 form-control-personlized"
                            rows={12}
                            spellCheck={false}
                            disabled
                        />
                    </Col>
                    <Col>
                        {task1 ? <Form.Control
                            className="mt-4"
                            as="textarea"
                            rows={12}
                            spellCheck={false}
                            value={task1Answer}
                            onChange={onChangeAnswer1}

                        />
                            :
                            <Form.Control
                                className="mt-4"
                                as="textarea"
                                rows={12}
                                spellCheck={false}
                                value={task2Answer}
                                onChange={onChangeAnswer2}
                            />}
                        <p>Word Count: {wordCounter}</p>
                    </Col>
                </Row>
                <Row className="fixed-bottom mx-2">
                    <Col xs="1">
                        <Form.Check type="checkbox" label="Review" />
                    </Col>
                    <Col className="border border-bottom-0 rounded" xs="10">
                        <Button className="m-1" onClick={handleButton1}>1</Button>
                        <Button className="m-1" onClick={handleButton2}>2</Button>
                    </Col>
                    <Col xs="1">
                        <Button onClick={askConfirmation}>Save</Button>
                    </Col>
                </Row>
            </Container>
        </main>
    )
}

export default FullTrainingWriting
