import React from 'react';

function Footer() {
    return (
        <div className="py-4 text-center bg-primary text-white">
            Powered by hannwebsites. <a target="_blank" className="btn btn-primary" href="www.hannwebsites.com">www.hannwebsites.com</a>
        </div>
    );
}

export default Footer;
