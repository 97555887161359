import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Form, Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./writingPage.scss"
//backend
import { database } from '../../firebase/config'
import { auth } from '../../firebase/config'


function WritingPage(props) {
    const [showAlert, setShowALert] = useState(false)
    const [word, setWord] = useState(" ")
    const [words, setWords] = useState(0)
    const [timer, setTimer] = useState(props.location.state.questionTime / 60)
    const [writingId, setWritingId] = useState('')

    useEffect(() => {
        var date = new Date()
        async function saveTempData() {
            const res = await database.collection('answers').add({
                temporarySave: {
                    "ans_gradedBy": "teacher",
                    "ans_gradedComments": "Teacher comments here",
                    "ans_questionText": props.location.state.questionText,
                    "ans_response": "",
                    "ans_score": "0",
                    "ans_timeTook": "",
                    "ans_user": auth.currentUser ? auth.currentUser.email : "anonymous",
                    "ans_wordCount": "",
                    "date_time": date
                }
            })
            setWritingId(res.id)
        }
        saveTempData();
    }, [props.location.state.questionText])

    const question = props.location.state ?
        props.location.state.questionText :
        <p>Clic <Link to="/">here</Link> to write a question</p>

    const wordCounter = (event) => {
        setWord(event.target.value);
        localStorage.setItem('answer', word)
        let text = []
        text = word.match(/\w+/g) ? word.match(/\w+/g).push() : 0
        setWords(text)
    }

    const timerDecreaser = () => {
        setTimeout(() => { setTimer(timer - 1) }, 60000)
        return timer
    }

    const askConfirmation = () => {
        setShowALert(true)
    }

    const dismissConfirmation = () => {
        setShowALert(false)
    }

    const saveAnswer = async () => {
        var date = new Date()
        const data = {
            "ans_gradedBy": "teacher",
            "ans_gradedComments": "Teacher comments here",
            "ans_question_text": props.location.state.questionText,
            "ans_response": word,
            "ans_score": "0",
            "ans_timeTook": props.location.state.questionTime === 1200 ? timer - 20 : timer - 40,
            "ans_wordCount": words,
            "date_time": date
        }
        props.location.state.questionTime === 1200 ?
            await database.collection('answers').doc(writingId).set({ "ans_user": auth.currentUser ? auth.currentUser.email : "anonymous", task1: data }) :
            await database.collection('answers').doc(writingId).set({ "ans_user": auth.currentUser ? auth.currentUser.email : "anonymous", task2: data })
        setWord(" ")
        setWords(0)
        localStorage.clear("answer")
        props.history.push("/")
    }

    return (
        <main>
            <Modal
                show={showAlert}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Container className="p-4 d-flex flex-column align-items-center text-center">
                    <div className="m-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="orange" className="bi bi-exclamation-diamond" viewBox="0 0 16 16">
                            <path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                        </svg>
                    </div>
                    <p className="text-center">Are you sure you want to save your test? be aware of:</p>
                    <ol className="text-left">
                        <li>Your work is saved locally temporarly</li>
                        <li>You can Retrieve your writing with the code {writingId}</li>
                        <li>You will be redirected to homepage</li>
                    </ol>
                    <div className='text-center'>
                        <Button className="m-4" variant="success" onClick={saveAnswer}>yes, I'm sure</Button>
                        <Button className="m-4" variant="danger" onClick={dismissConfirmation}>No, Cancel</Button>
                    </div>
                </Container>
            </Modal>
            <div className="bg-dark">
                <Container fluid className="d-flex justify-content-between text-white">
                    <span >Writing ID {writingId}</span>
                    <span>{timerDecreaser()} min left</span>
                    <span> </span>
                </Container>
            </div>
            <Container fluid className="mt-4">
                <div className="border mx-1 my-2 pt-3 pl-3">
                    <h4 className="mb-3">Part {props.location.state.questionTime === 1200 ? "1" : "2"}</h4>
                    <p>You should spend about {props.location.state.questionTime === 1200 ? "20" : "40"}
                    minutes on this part. Write at least {props.location.state.questionTime === 1200 ? "150" : "250"} words</p>
                </div>
                <Row className="mx-2 shadow mid-height">
                    <Col>
                        <Form.Control as="textarea" rows={12}
                            className="mt-4 no-bg no-border form-control-personlized"
                            onChange={wordCounter}
                            spellCheck={false}
                            placeholder={question}
                            disabled></Form.Control>
                    </Col>
                    <Col>
                        <Form.Control as="textarea" rows={12} className="mt-4" value={word} onChange={wordCounter} spellCheck={false} />
                        <p>Word Count {words}</p>
                    </Col>
                </Row>
                <Row className="fixed-bottom mx-2">
                    <Col xs="1">
                        <Form.Check type="checkbox" label="Review" />
                    </Col>
                    <Col className="border border-bottom-0 rounded" xs="10">
                        <Button className="m-1" >NAA</Button>
                    </Col>
                    <Col xs="1">
                        <Button onClick={askConfirmation}>Save</Button>
                    </Col>
                </Row>
            </Container>
        </main>
    )
}

export default WritingPage
