import React, { useState } from 'react'
import { Container, ButtonGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'

//CSS
import './landingPage.css'

//Components
import ModalTaskSelector from '../../auxComponents/modalTaskSelector/ModalTaskSelector'

//Resources
import next from '../../assets/Next.svg'

const LandingPage = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [taskName, setTaskName] = useState(false)
    const [taskTime, setTaskTime] = useState([])

    const handleClose = () => {
        setShowModal(!showModal)
    }

    const handleClickTask1 = () => {
        setTaskName("Task 1")
        handleClose()
        setTaskTime(1200)
    }

    const handleClickTask2 = () => {
        handleClose()
        setTaskName("Task 2")
        setTaskTime(2400)
    }

    const handleSubmit = (writing_page, topic, questionText, essayText, questionType, questionTime) => {
        props.history.push(writing_page, { topic, questionText, essayText, questionType, questionTime });
    }

    const handleClickFullTest = () => {
        handleClose()
        setTaskName("Full Training")
        setTaskTime(3600)
    }

    return (
        < main >
            <div className="background-landing-page text-white">
                <Container>
                    <h1 className="text-monospace">looking for a place to practice writing for IELST?</h1>
                    <h4 className="pt-4">Simulation IELTS is a web tool that helps you practice writing for computer based IELTS test. You can either log in to save your history or save the writing ID for future reference.</h4>
                </Container>
                <div className="text-center scroll-animation">
                    <div>
                        <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="34.5" cy="34.5" r="34.5" fill="white" />
                            <path d="M35.9393 54.0607C36.5251 54.6464 37.4749 54.6464 38.0607 54.0607L47.6066 44.5147C48.1924 43.9289 48.1924 42.9792 47.6066 42.3934C47.0208 41.8076 46.0711 41.8076 45.4853 42.3934L37 50.8787L28.5147 42.3934C27.9289 41.8076 26.9792 41.8076 26.3934 42.3934C25.8076 42.9792 25.8076 43.9289 26.3934 44.5147L35.9393 54.0607ZM35.5 17L35.5 53L38.5 53L38.5 17L35.5 17Z" fill="#216869" />
                        </svg>
                        <h3 className="text-monospace pt-2">scrooll down and start writing</h3>

                    </div>
                </div>
            </div>
            <div className="background-test-section d-flex align-items-center">
                <div className="background-test-section-higlight py-4">
                    <Container>
                        <h4 className="my-3 text-center text-primary">Choose your text type</h4>
                        <div className="text-center">
                            <ButtonGroup>
                                <Button onClick={handleClickTask1} variant="secodary" className="border py-1 px-5" >Task 1</Button>
                                <Button onClick={handleClickTask2} variant="secodary" className="border py-1 px-5">Task 2</Button>
                                <Button onClick={handleClickFullTest} variant="primary" className="border py-1 px-5">Full Test</Button>
                            </ButtonGroup>                </div>
                        <div className="my-2 d-flex justify-content-around align-items-center pt-4 text-center">
                            <h3 className="text-monospace">STEPS</h3>
                            <img src={next} alt="Next Button" />
                            <div>
                                <div className="d-flex justify-content-center">
                                    <h2 className="bg-secondary step-numbers rounded-circle pt-1">1</h2>
                                </div>
                                <p className="text-monospace">Choose your<br />test type.</p>
                            </div>
                            <img src={next} alt="Next Button" />
                            <div>
                                <div className="d-flex justify-content-center">
                                    <h2 className="bg-secondary step-numbers rounded-circle pt-1">2</h2>
                                </div>                        <p className="text-monospace">Paste your<br />question.</p>
                            </div>
                            <img src={next} alt="Next button" />
                            <div>
                                <div className="d-flex justify-content-center">
                                    <h2 className="bg-secondary step-numbers rounded-circle pt-1">3</h2>
                                </div>
                                <p className="text-monospace">Take your test<br />.</p>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            <ModalTaskSelector
                show={showModal}
                taskName={taskName}
                taskTime={taskTime}
                close={handleClose}
                submit={handleSubmit} />
        </main >
    )
}

export default LandingPage
