import React, { useState } from 'react';
import { Modal, Button, Form, FormLabel, FormControl, InputGroup } from 'react-bootstrap';

//Styles
import "./modallogin.scss";


function ModalLogIn({ show, close, signUp, auth, database, setUser }) {


    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPass, setShowPass] = useState(false)
    const [showErrors, setShowErrors] = useState("")

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (!email || !password) { setShowErrors("User And Password are required") } else {
            try {
                auth.setPersistence("session")
                const authRes = await auth.signInWithEmailAndPassword(email, password);
                const res = await database.collection("users").doc(authRes.user.uid).get()
                console.log(authRes.user.uid)
                const data = res.data()

                setUser({
                    user_firstName: data.user_firstName,
                    user_lastName: data.user_lastName,
                    user_initials: data.user_initials
                })
                setPassword("")
                setEmail("")
                close()
            } catch (error) {
                setShowErrors("An error have happened, please verify your account or try again")
                setPassword("")
            }
        }
    }

    const showPassword = () => {
        setShowPass(!showPass)
    }

    return (
        <Modal centered show={show} onHide={close} className="modal-border">

            <Modal.Header closeButton className="text-monospace text-center justify-content-center p-4 border-0 text-dark-blue">
                <Modal.Title>
                    <FormLabel> Sign In </FormLabel>
                    <h5>To keep tracking of your IELTS Writing</h5>
                </Modal.Title>
                <Modal.Title>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Form>
                    <div className="text-danger py-2">{showErrors}</div>
                    <FormLabel> E-mail Address </FormLabel>
                    <InputGroup className="border border-warning rounded">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="bg-white border-0">
                                <svg width="16" height="24" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 3.66667L20.3333 17.8333L38.6667 3.66667M2 2H38.6667V28.6667H2V2Z" stroke="#0F084B" stroke-width="3.33333" />
                                </svg>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            className="border-0 text-dark-blue"
                            as="input"
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="user@gmail.com">
                        </FormControl>
                    </InputGroup>
                    <FormLabel className="pt-2"> Password </FormLabel>
                    <InputGroup className="border border-warning rounded pr-1">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="bg-white border-0">
                                <svg width="16" height="24" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5 25.3846V34.1538M7.8 19.5385V10.7692C7.8 8.44349 8.7166 6.213 10.3482 4.56845C11.9797 2.9239 14.1926 2 16.5 2C18.8074 2 21.0203 2.9239 22.6518 4.56845C24.2834 6.213 25.2 8.44349 25.2 10.7692V19.5385H7.8ZM31 40V19.5385H2V40H31Z" stroke="#0F084B" stroke-width="3.33333" />
                                </svg>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            className="border-0 text-dark-blue"
                            as="input"
                            type={showPass ? "text" : "password"}
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="******" />
                        <InputGroup.Prepend>
                            <InputGroup.Text className="bg-white border-0">
                                <small onClick={showPassword} role="button" >
                                    {showPass ? "HIDE" : "SHOW"}
                                </small>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                    </InputGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer className="p-4 border-0">
                <Button
                    className="text-dark-blue btn-block text-monospace"
                    variant="warning"
                    type="submit"
                    onClick={handleSubmit}>
                    Log In
                </Button>
            </Modal.Footer>
            <div className="text-center text-monospace pb-4">
                <h5 className="text-dark-blue">Not a Member?</h5>
                <h5 role="button"
                    className="text-primary"
                    onClick={signUp}
                >Sign Up</h5>
            </div>
        </Modal>
    )
}

export default ModalLogIn