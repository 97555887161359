import React, { useState } from 'react'
import { Modal, Button, Form, FormLabel, FormControl, InputGroup } from 'react-bootstrap'


//Styles
import "./modalsignup.scss"

function ModalSignUp({ show, close, signIn, auth, database, setUser }) {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [showPass, setShowPass] = useState(false)
    const [showErrors, setShowErrors] = useState("")

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (!email || !password || !firstName || !lastName) { setShowErrors("All fields are mandatory") } else {
            try {
                const authRes = await auth.createUserWithEmailAndPassword(email, password)
                await database.collection("users").doc(authRes.user.uid).set({
                    user_email: email,
                    user_firstName: firstName,
                    user_lastName: lastName,
                    user_testEvaluator: "anonimous",
                    user_quantityTestTaken: 0,
                    user_type: "testTaker",
                    user_initials: firstName.slice(0, 1) + lastName.slice(0, 1)
                })
                setUser({
                    user_firstName: firstName,
                    user_lastName: lastName,
                    user_initials: firstName.slice(0, 1) + lastName.slice(0, 1)
                })
                setPassword("")
                setEmail("")
                setFirstName("")
                setLastName("")
                setShowPass(false)
                close()
            } catch (error) {
                console.log(error.message)
                setShowErrors("Something unexpected happened, please try again.")
                setPassword("")
            }
        }
    }
    const showPassword = () => {
        setShowPass(!showPass)
    }
    return (
        <Modal centered show={show} onHide={close} className="modal-border">
            <Modal.Header closeButton className="text-monospace text-center justify-content-center border-0">
                <Modal.Title>
                    <FormLabel className=""> Sign Up </FormLabel>
                    <h5>To keep tracking of your IELTS Writing</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Form>
                    <div className="text-danger py-2">{showErrors}</div>
                    <FormLabel> E-mail Address </FormLabel>
                    <InputGroup className="border border-warning rounded">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="bg-white border-0">
                                <svg width="16" height="24" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 3.66667L20.3333 17.8333L38.6667 3.66667M2 2H38.6667V28.6667H2V2Z" stroke="#0F084B" stroke-width="3.33333" />
                                </svg>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            className="border-0"
                            as="input"
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="user@gmail.com" />
                    </InputGroup>
                    <FormLabel className="pt-2"> First Name </FormLabel>
                    <InputGroup className="border border-warning rounded">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="bg-white border-0">
                                <svg width="16" height="24" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3333 40.3333V32V40.3333ZM23.6667 40.3333V32V40.3333ZM2 40.3333V32C2 23.7167 8.71667 18.6667 17 18.6667C25.2833 18.6667 32 23.7167 32 32V40.3333H2ZM17 18.6667C19.2101 18.6667 21.3298 17.7887 22.8926 16.2259C24.4554 14.6631 25.3333 12.5435 25.3333 10.3333C25.3333 8.1232 24.4554 6.00358 22.8926 4.44078C21.3298 2.87797 19.2101 2 17 2C14.7899 2 12.6702 2.87797 11.1074 4.44078C9.54464 6.00358 8.66667 8.1232 8.66667 10.3333C8.66667 12.5435 9.54464 14.6631 11.1074 16.2259C12.6702 17.7887 14.7899 18.6667 17 18.6667V18.6667Z" stroke="#0F084B" stroke-width="3.33333" />
                                </svg>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            className="border-0"
                            as="input"
                            type="text"
                            value={firstName}
                            onChange={handleFirstNameChange}
                            placeholder="Hann" />
                    </InputGroup>
                    <FormLabel className="pt-2"> Last Name </FormLabel>
                    <InputGroup className=" border border-warning rounded">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="bg-white border-0">
                                <svg width="16" height="24" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3333 40.3333V32V40.3333ZM23.6667 40.3333V32V40.3333ZM2 40.3333V32C2 23.7167 8.71667 18.6667 17 18.6667C25.2833 18.6667 32 23.7167 32 32V40.3333H2ZM17 18.6667C19.2101 18.6667 21.3298 17.7887 22.8926 16.2259C24.4554 14.6631 25.3333 12.5435 25.3333 10.3333C25.3333 8.1232 24.4554 6.00358 22.8926 4.44078C21.3298 2.87797 19.2101 2 17 2C14.7899 2 12.6702 2.87797 11.1074 4.44078C9.54464 6.00358 8.66667 8.1232 8.66667 10.3333C8.66667 12.5435 9.54464 14.6631 11.1074 16.2259C12.6702 17.7887 14.7899 18.6667 17 18.6667V18.6667Z" stroke="#0F084B" stroke-width="3.33333" />
                                </svg>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            className="border-0"
                            as="input"
                            type="text"
                            value={lastName}
                            onChange={handleLastNameChange}
                            placeholder="Smith" />
                    </InputGroup>
                    <FormLabel className="pt-2"> Password </FormLabel>
                    <InputGroup className="border border-warning rounded pr-1">
                        <InputGroup.Prepend>
                            <InputGroup.Text className="bg-white border-0">
                                <svg width="16" height="24" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5 25.3846V34.1538M7.8 19.5385V10.7692C7.8 8.44349 8.7166 6.213 10.3482 4.56845C11.9797 2.9239 14.1926 2 16.5 2C18.8074 2 21.0203 2.9239 22.6518 4.56845C24.2834 6.213 25.2 8.44349 25.2 10.7692V19.5385H7.8ZM31 40V19.5385H2V40H31Z" stroke="#0F084B" stroke-width="3.33333" />
                                </svg>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            className="border-0"
                            as="input"
                            type={showPass ? "text" : "password"}
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="******" />
                        <InputGroup.Prepend>
                            <InputGroup.Text className="bg-white border-0">
                                <small onClick={showPassword} role="button" >
                                    {showPass ? "HIDE" : "SHOW"}
                                </small>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                    </InputGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer className="border-0 p-4">
                <Button className="btn-block text-monospace" variant="warning" type="submit" onClick={handleSubmit}>
                    Create your free account
                </Button>
            </Modal.Footer>
            <div className="text-center text-monospace pb-4">
                <h5 className="text-dark-blue">Alredy registered?</h5>
                <h5 role="button"
                    className="text-primary"
                    onClick={signIn}
                >Sign In</h5>
            </div>
        </Modal >
    )
}

export default ModalSignUp