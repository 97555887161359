import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, } from 'react-bootstrap'

//styles
import "./ViewPrevWriting.scss"

//database

import { database } from "../../../firebase/config"

function ViewPrevWriting(props) {

    const [task1, setTask1] = useState("")
    const [task1Question, setTask1Question] = useState("")
    const [task2Question, setTask2Question] = useState("")
    const [task1Answer, setTask1Answer] = useState("")
    const [task2Answer, setTask2Answer] = useState("")
    const [wordCounter, setWordCounter] = useState("")
    const [timeTakenTask1, setTimeTakenTask1] = useState("")
    const [timeTakenTask2, setTimeTakenTask2] = useState("")
    let testId = props.match.params.id

    useEffect(() => {
        async function getItems() {
            var snapshot
            const res = await database.collection('answers').doc(testId).get()
            snapshot = res.data()
            setTask1(snapshot.task1 ? true : false)
            setTimeTakenTask1(snapshot.task1 && snapshot.task1.ans_timeTook)
            setTimeTakenTask2(snapshot.task2 && snapshot.task2.ans_timeTook)
            setTask1Question(snapshot.task1 && snapshot.task1.ans_question_text)
            setTask2Question(snapshot.task2 && snapshot.task2.ans_question_text)
            setTask1Answer(snapshot.task1 && snapshot.task1.ans_response)
            setTask2Answer(snapshot.task2 && snapshot.task2.ans_response)
            setWordCounter(snapshot.task1 ? snapshot.task1.ans_wordCount : snapshot.task2.ans_wordCount)
        }
        getItems()
    }, [])

    const countWords = (textToCount) => {
        let text = []
        text = textToCount.match(/\w+/g) ? textToCount.match(/\w+/g).push() : 0
        setWordCounter(text)
    }

    const handleButton1 = () => {
        setTask1(true)
        countWords(task1Answer)
    }

    const handleButton2 = () => {
        setTask1(false)
        countWords(task2Answer)
    }

    return (
        <main>
            <div className="bg-dark">
                <Container fluid className="d-flex justify-content-between text-white">
                    <span >Writing ID {testId}</span>
                    <span>You took {task1 ? timeTakenTask1 : timeTakenTask2} min</span>
                    <span> </span>
                </Container>
            </div>
            <Container fluid >
                <div className="border mx-1 my-2 pt-3 pl-3">
                    <h4 className="mb-3">Part {task1 ? "1" : "2"}</h4>
                    <p>You should spend about {task1 ? "20" : "40"} minutes on this part. Write at least {task1 ? "150" : "250"} words</p>
                </div>
                <Row className="mx-1 shadow mid-height">
                    <Col>
                        <Form.Control as="textarea"
                            placeholder={task1 ? task1Question : task2Question}
                            className="mt-4 border-0 form-control-personlized"
                            rows={12}
                            spellCheck={false}
                            disabled
                        />
                    </Col>
                    <Col>
                        {task1 ? <Form.Control
                            className="mt-4 form-control-personlized no-border"
                            as="textarea"
                            rows={12}
                            spellCheck={false}
                            value={task1Answer}
                            disabled
                        />
                            :
                            <Form.Control
                                className="mt-4 form-control-personlized no-border"
                                as="textarea"
                                rows={12}
                                spellCheck={false}
                                value={task2Answer}
                                disabled
                            />}
                        <p>Word Count: {wordCounter}</p>
                    </Col>
                </Row>
                <Row className="fixed-bottom mx-2">
                    <Col xs="1">
                        <Form.Check type="checkbox" label="Review" />
                    </Col>
                    <Col className="border border-bottom-0 rounded" xs="10">
                        {
                            (task1Answer && task1Answer) &&
                            <Button className="m-1" onClick={handleButton1}>1</Button>
                        }
                        {
                            (task2Answer && task2Answer) &&
                            <Button className="m-1" onClick={handleButton2}>2</Button>
                        }
                    </Col>
                </Row>
            </Container>
        </main>
    )
}

export default ViewPrevWriting
