import React, { useEffect, useState } from 'react'
import { Container, Button, Row, Table, FormGroup, Form, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

//data base
import { database } from "../../../firebase/config"
import { auth } from "../../../firebase/config"

//components

import NotLoggedIn from "../../auxComponents/notLoggedIn/NotLoggedIn"


function Dashboard() {

    const [tableItems, setTableItems] = useState([])
    const [search, setSearch] = useState("")

    useEffect(() => {
        let snapshot = []
        const user = auth.currentUser ? auth.currentUser.email : ""
        async function getItems() {
            try {
                const res = await database.collection('answers').where("ans_user", "==", user).get()
                res.forEach(doc => snapshot.push({ ID: doc.id, data: doc.data() }))
                setTableItems(snapshot)
            } catch (error) {
                console.log(error.message)
            }
        }
        getItems()
    }, [])


    let tableDataRow = tableItems.map((tableItem) =>
        <tr key={tableItem.ID}>
            <td>{tableItem.ID}</td>
            <td>
                {tableItem.data.task1 && `Task 1`}
                {tableItem.data.task1 && tableItem.data.task2 && <br></br>}
                {tableItem.data.task2 && `Task 2`}
            </td>
            <td>{tableItem.data.task1 ? tableItem.data.task1.ans_timeTook : tableItem.data.task2.ans_timeTook}</td>
            <td>
                {tableItem.data.task1 && `Task 1: ${tableItem.data.task1.ans_wordCount} Words`}
                {tableItem.data.task1 && tableItem.data.task2 && <br></br>}
                {tableItem.data.task2 && `Task 2: ${tableItem.data.task2.ans_wordCount} Words`}
            </td>
            <td>
                {tableItem.data.task1 && `Task 1: ${tableItem.data.task1.ans_response.slice(0, 60)} ...`}
                {tableItem.data.task1 && tableItem.data.task2 && <br></br>}
                {tableItem.data.task2 && `Task 2: ${tableItem.data.task2.ans_response.slice(0, 60)} ...`}
            </td>
            <td><Link to={`/view_test/${tableItem.ID}`}><Button variant="primary">view</Button></Link></td>
        </tr >
    )

    const onChangeSearch = (event) => {
        setSearch(event.target.value)
    }

    const searchHandle = async () => {
        var regex = /^[a-zA-Z]+$/;
        if (search !== "" && search.length === 20 && regex.test(search) === false) {
            try {
                const res = await database.collection("answers").doc(search).get()
                if (res.exists) {
                    setTableItems([{ ID: res.id, data: res.data() }])
                } else {
                    alert("item not found")
                    setTableItems([])
                }
            } catch (error) {
                console.log(error.meessage)
            }
        } else {
            alert("the writing ID is invalid, please verify and try again")
        }

    }

    return (
        <main style={{ height: "100vh" }}>
            <div className="mt-3">
                <Container>
                    <Form>
                        <FormGroup as={Row} controld="searchWriting">
                            <Col sm="8">
                                <Form.Control onChange={onChangeSearch} type="text" placeholder="Enter your writing ID" />
                                <small>Writing ID is an alphanumeric value of 20 characters</small>
                            </Col>
                            <Col sm="2">
                                <Button onClick={searchHandle}>
                                    Search
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Container>
                <Container fluid>
                    {tableItems.length === 0 ? <Container className="py-2"><NotLoggedIn /></Container> :
                        <Table>
                            <thead className="bg-secondary">
                                <tr>
                                    <th>ID</th>
                                    <th>Test Type</th>
                                    <th>Time</th>
                                    <th># of Words</th>
                                    <th>Answer</th>
                                    <th>view All</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableDataRow}
                            </tbody>
                        </Table>}
                </Container>
            </div>
        </main >
    )
}

export default Dashboard
