import React from 'react'
import { Container, Card, CardColumns, Button } from 'react-bootstrap'

import './resourcesPage.css'

function ResourcesPage() {
    return (
        <main>
            <div className="resources-banner-container">
                <div className="resources-banner-container-cover d-flex align-items-center">
                    <Container>
                        <h1 className="text-monospace">Find Resources for your training</h1>
                        <h4 className="py-4">In the following websites you will be able to find questions and role model answers to use them as a practice for this application</h4>
                    </Container>
                </div>
            </div>
            <Container className="py-4">
                <CardColumns>
                    <Card className="p-2" border="black" style={{ width: "18rem" }}>
                        <Card.Header>
                            Liz Ielts
                    </Card.Header>
                        <Card.Text>
                            IELTS preparation with Liz: Free Tips, Lessons & English.
                    </Card.Text>
                        <a target="_blank" rel="noopener noreferrer" href="https://ieltsliz.com/">
                            <Button>
                                Go to website
                        </Button>
                        </a>
                    </Card>
                    <Card className="p-2" border="black" style={{ width: "18rem" }}>
                        <Card.Header>
                            ielts-blog
                    </Card.Header>
                        <Card.Text>
                            IELTS-blog has everything you need to prepare for the IELTS exam by yourself.
                    </Card.Text>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.ielts-blog.com/">
                            <Button>
                                Go to website
                        </Button>
                        </a>
                    </Card>
                    <Card className="p-2" border="black" style={{ width: "18rem" }}>
                        <Card.Header>
                            ielts exam
                    </Card.Header>
                        <Card.Text>
                            IELTS course, english course, online writing courses, online english speaking.
                    </Card.Text>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.ielts-exam.net/">
                            <Button>
                                Go to website
                        </Button>
                        </a>
                    </Card>
                </CardColumns>
            </Container>
        </main >
    )
}

export default ResourcesPage
